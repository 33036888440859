
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import CreateModalitasBelajarComponent from "@/views/psy/modalitas-belajar/component/CreateModalitasBelajarComponent.vue";

export default defineComponent({
  name: "modalitas-belajar-adm",
  components: {
    CreateModalitasBelajarComponent,
  },
  setup() {
    let isopenmodal = ref(false)
    var isUpdate = ref(false)
    var itemsUpdate = ref({})
    var modalitasBelajarCache = ref([] as Array<any>)
    var modalitasBelajarExampleCache = ref([] as Array<any>)
    const TypeTestID = process.env.VUE_APP_TYPE_TEST_ID_MODALITAS_BELAJAR

    onMounted(() => {
      setCurrentPageBreadcrumbs("MODALITAS BELAJAR", []);
      getModalitasBelajar()
    });

    function openModalAdd(isupdate, itemsupdate){
      isUpdate.value = isupdate
      itemsUpdate.value = itemsupdate
      isopenmodal.value = true
    }

    function isModalClose(val) {
      isopenmodal.value = false
    }

    function isNeedRefresh(val) {
      getModalitasBelajar()
    }

    async function getModalitasBelajar(){
      modalitasBelajarCache.value = []
      modalitasBelajarExampleCache.value = []
      await Services.GetData(ApiService2, `master/questions-modalitas-belajar/masterid`, TypeTestID, response=>{
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].Numb = Number(response.data[i].QuestionNumber)
          if(response.data[i]['IsExample'] == 1){           
            modalitasBelajarExampleCache.value.push(response.data[i])
          }else{
            modalitasBelajarCache.value.push(response.data[i])
          }
        }
         modalitasBelajarCache.value = modalitasBelajarCache.value.orderBy(["Numb"], "ASC")
      }, err =>{
        err
      })
    }

    function onDelete(val){
      deleteModalitasBelajar(val.QuestionID)
    }

     async function deleteModalitasBelajar(QuestionID){
      await Services.DeleteData(ApiService2, `master/questions-modalitas-belajar/${QuestionID}`, response=>{
        getModalitasBelajar()
      }, err =>{
        err
      })
    }

    function onUpdate(val){
      openModalAdd(true, val)
    }

    function formatText(text) {
      // Replace all \r\n and \n with <br> to create line breaks
      return text.replace(/\r?\n/g, '<br>');
    }

    return {
      TypeTestID,
      modalitasBelajarCache,
      modalitasBelajarExampleCache,
      openModalAdd,
      isModalClose,
      isNeedRefresh,
      isopenmodal,
      isUpdate,
      itemsUpdate,
      formatText,
      onDelete,
      onUpdate,
    };
  },
});
