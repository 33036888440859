import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "row g-5 g-xl-8" }
const _hoisted_3 = { class: "card-body py-3" }
const _hoisted_4 = { class: "table-responsive" }
const _hoisted_5 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_6 = { class: "text-dark fw-bolder d-block fs-6" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  title: "",
  "data-bs-original-title": "Edit"
}
const _hoisted_11 = { class: "svg-icon svg-icon-3" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_CreateModalitasBelajarComponent = _resolveComponent("CreateModalitasBelajarComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-lg btn-primary me-3",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModalAdd(false, {})))
      }, "Buat Soal")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("table", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
              _createElementVNode("tr", { class: "fw-bolder text-muted" }, [
                _createElementVNode("th", { class: "min-w-50px" }, "No"),
                _createElementVNode("th", { class: "min-w-250px" }, "Soal"),
                _createElementVNode("th", { class: "min-w-50px" }, "Aksi")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalitasBelajarCache, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(item.QuestionNumber), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("p", {
                      class: "text-dark fw-bolder d-block fs-6",
                      innerHTML: _ctx.formatText(item.QuestionDescription)
                    }, null, 8, _hoisted_7)
                  ]),
                  _createElementVNode("td", _hoisted_8, [
                    _createElementVNode("p", {
                      onClick: ($event: any) => (_ctx.onUpdate(item)),
                      class: "btn btn-icon btn-active-light-primary w-30px h-30px me-3",
                      "data-bs-toggle": "tooltip",
                      title: "",
                      "data-kt-customer-payment-method": "update",
                      "data-bs-original-title": "Update"
                    }, [
                      _createElementVNode("span", _hoisted_10, [
                        _createElementVNode("span", _hoisted_11, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                        ])
                      ])
                    ], 8, _hoisted_9),
                    _createElementVNode("p", {
                      onClick: ($event: any) => (_ctx.onDelete(item)),
                      class: "btn btn-icon btn-active-light-primary w-30px h-30px me-3",
                      "data-bs-toggle": "tooltip",
                      title: "",
                      "data-kt-customer-payment-method": "delete",
                      "data-bs-original-title": "Delete"
                    }, [
                      _createElementVNode("span", _hoisted_13, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                      ])
                    ], 8, _hoisted_12)
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_CreateModalitasBelajarComponent, {
      tipeTestId: _ctx.TypeTestID,
      isopenmodal: _ctx.isopenmodal,
      onModalClose: _ctx.isModalClose,
      isupdate: _ctx.isUpdate,
      itemsupdate: _ctx.itemsUpdate,
      onIsneedrefresh: _ctx.isNeedRefresh
    }, null, 8, ["tipeTestId", "isopenmodal", "onModalClose", "isupdate", "itemsupdate", "onIsneedrefresh"])
  ], 64))
}