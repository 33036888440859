
import { defineComponent, ref, toRefs, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { string } from "yup/lib/locale";

interface ModalitasBelajar {  
  QuestionID: number
  QuestionNumber: number
  QuestionDescription: string
  TypeTestID: string
  IsExample: number
  Times: number
  KeyList: string
}

export default defineComponent({
  name: "create-modalitas-belajar-component",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    isopenmodal:Boolean,
    isupdate: Boolean,
    itemsupdate: {
        type: Object,
        default: () => ({}),
    },
    tipeTestId: String,
  },
  setup(props, {emit}) {
    const { isopenmodal, itemsupdate } = toRefs(props);
    let dialogAddGroup = ref(false)
    const submitButton = ref<HTMLElement | null>(null);
    var modalitasBelajar = ref<ModalitasBelajar>({
                QuestionID: 0,
                QuestionNumber: 0,
                QuestionDescription: "",
                TypeTestID: props.tipeTestId || '',
                IsExample: 0,
                Times: 0,
                KeyList: "",
              })

    function resetDataModalitasBelajar(){
        modalitasBelajar.value.QuestionID = 0
        modalitasBelajar.value.QuestionNumber = 0
        modalitasBelajar.value.QuestionDescription= ""
        modalitasBelajar.value.TypeTestID	= props.tipeTestId || ''
        modalitasBelajar.value.IsExample	=  0
        modalitasBelajar.value.Times = 0
        modalitasBelajar.value.KeyList= ""
    }
    
    watch(isopenmodal, (newVal, oldVal) => {
      resetDataModalitasBelajar()

      if(props.isupdate){
        modalitasBelajar.value.QuestionID = props.itemsupdate.QuestionID
        modalitasBelajar.value.QuestionNumber = props.itemsupdate.QuestionNumber
        modalitasBelajar.value.QuestionDescription= props.itemsupdate.QuestionDescription
        modalitasBelajar.value.TypeTestID	=  props.itemsupdate.TypeTestID
        modalitasBelajar.value.IsExample	=  props.itemsupdate.IsExample
        modalitasBelajar.value.Times = props.itemsupdate.Times
        modalitasBelajar.value.KeyList= props.itemsupdate.KeyList
      }

      if(newVal){
        dialogAddGroup.value = true
      }
    });

    function modalClose(){
      dialogAddGroup.value = false
      emit("modalClose", true);
    }

    const modalitasBelajarValidator = Yup.object().shape({
      fno: Yup.string().required().label("No."),
      fname: Yup.string().required().label("Pertanyaan"),
    });

    function saveForm (data_save){
      var saveObj = {}
      saveObj = Object.assign({}, data_save._rawValue)

      if(props.isupdate){
        update(saveObj)
      }else{
        save(saveObj)
      }
    };

    async function save(saveObj){
      var responseCache = []
      await Services.PostData(ApiService2, `master/questions-modalitas-belajar/`, saveObj, response=>{
        responseCache = response.data
        toast("Save Berhasil!", 
        {
          autoClose: 1000,
          type: 'success',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        emit("isneedrefresh", 1);
        cancelModal()
      }, err =>{
        err
        toast("Save Gagal", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        cancelModal()
      })
    }

    async function update(saveObj){
      var responseCache = []
      await Services.PutData(ApiService2,  `master/questions-modalitas-belajar/${saveObj.QuestionID}`, saveObj, response=>{
        responseCache = response.data
        toast("Save Berhasil!", 
        {
          autoClose: 1000,
          type: 'success',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        emit("isneedrefresh", 1);
        cancelModal()
      }, err =>{
        err
        toast("Save Gagal", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        cancelModal()
      })
    }

    const saveModalitasBelajar = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
        saveForm(modalitasBelajar)
      }
    };

    function cancelModal(){
      resetDataModalitasBelajar()
      modalClose()
    }

    return {
      // data,
      modalitasBelajar,
      modalitasBelajarValidator,
      saveModalitasBelajar,
      submitButton,
      dialogAddGroup,
      cancelModal,
      modalClose,
    };
  },
});
